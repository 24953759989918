<template>
  <section class="hero">
    <div class="container">
      <div class="__content">
        <h1 class="hero-title">Решения для диагностики и развития эмоционального интеллекта</h1>
        <div class="hero-text">
          В каталоге собраны более 60 решений для оценки уровня эмоционального
          интеллекта и его интенсивного развития. В основе всех продуктов лежат
          научные разработки команды ведущих российских ученых в области
          эмоционального интеллекта.
        </div>
        <div class="hero-action btn-action display--flex">
          <button
            @click="openConsultModal = true"
            type="button"
            class="btn btn-o-accent btn-sm"
          >
            Проконсультироваться
          </button>
        </div>
      </div>
    </div>
  </section>

  <section class="index-section s-products--lg">
    <div class="container">
      <div class="products-grid grid-2 display--grid">
        <product-card-lg
          v-for="(tag, index) of generalTags"
          :key="tag.id"
          :title="tag.title"
          :label="categoryInfo[index]"
          :imagePath="svgs[index]"
          :link="`/products/${tag.title}`"
          :description="categoryDescription[index]"
        ></product-card-lg>
      </div>
    </div>
  </section>
  <pop-up-form
    postField
    companyField
    type="ConsultProducts"
    title="Получить консультацию"
    v-if="openConsultModal"
    @closeModal="openConsultModal = false"
  />
  <product-slider
    title="Популярные"
  ></product-slider>
</template>

<script>
import ProductCardLg from "../../components/product-card/ProductCardLg.vue";
import ProductSlider from "../../components/sliders/product-slider/ProductSlider.vue";

import { useStore } from "vuex";
import { computed, ref } from "@vue/runtime-core";
import PopUpForm from "../../components/pop-up-form/PopUpForm.vue";
import { useHead } from '@vueuse/head'

export default {
  name: "catalog-page",
  components: { ProductSlider, ProductCardLg, PopUpForm },
  setup() {
    useHead({
      title:
        "Каталог решений для диагностики и развития эмоционального интеллекта",
      meta: [
        {
          property: "og:description",
          content:
            "В каталоге собраны более 60 решений для оценки уровня эмоционального интеллекта и его интенсивного развития. В основе всех продуктов лежат научные разработки команды ведущих российских ученых в области эмоционального интеллекта.",
        },
        {
          property: "og:keywords",
          content:
            "развитие эмоционального интеллекта, эмоциональный интеллект скачать, эмоциональный интеллект купить, игра эмоциональный интеллект, эмоциональный интеллект онлайн, уровень эмоционального интеллекта, курс эмоциональный интеллект, эмоциональный интеллект руководителя, программа развития эмоционального интеллекта, программы по эмоциональному интеллекту",
        },
      ],
    });
    let store = useStore();

    let openConsultModal = ref(false),
      svgs = [
        "HR,T&D.svg",
        "Trainer.svg",
        "Top_Manager.svg",
        "Individual_1.svg",
      ],
      categoryInfo = [
        "24 продукта от 4 000 р.",
        "10 продуктов от 12 000 р.",
        "12 продукта от 14 000 р.",
        "4 продукта от 12 000 р.",
      ],
      categoryDescription = [
        "Оценка и развитие ЭИ помогает ускорить процесс найма, определить сильные стороны руководителей и их сотрудников, а также строить планы развития зон роста команды.",
        "Онлайн-интенсивы, тренинги под ключ и другие digital-решения для развития себя как эксперта в области эмоционального интеллекта по передовой методике.",
        "Решения для развития команд, усиления собственных лидерских компетенций, организации работы сотрудников и создания продуктивной рабочей атмосферы.",
        "Для тех, кто хочет узнать свой уровень эмоционального интеллекта и самостоятельно развить его до нужных показателей, чтобы повысить качество своей жизни.",
      ];

    let generalTags = computed(() => store.state.products.generalTags);


    return {
      openConsultModal,
      generalTags,
      svgs,
      categoryInfo,
      categoryDescription,
    };
  },
};
</script>

<style>
</style>
